<template>
  <module-template
    id="scrolltainer"
    title
    :canUpload="vaultWriteAccess"
    canList
    canDownload
    @list="listFiles"
    @download="onDownload"
    @upload="onShowUpload"
  >
    <template #title>
      <v-row dense class="px-1" align="center">
        {{ $t("global.concepts.file") }}
        <v-icon color="white" class="px-3" size="9">pic-arrow-right</v-icon>
        <div>{{ file?.name }}</div>
      </v-row>
    </template>

    <v-card>
      <v-card-text>
        <v-row dense style="max-width: 800px">
          <v-col style="font-weight: bold; text-align: right" cols="2">
            {{ $t("global.administration.vault") }}
          </v-col>

          <v-col class="" cols="2">
            {{ file?.vaultName }}
          </v-col>
        </v-row>

        <v-row dense style="max-width: 800px" class="mb-2">
          <v-col style="font-weight: bold; text-align: right" cols="2">
            {{ $t("global.concepts.file-environment") }}
          </v-col>

          <v-col cols="2">
            {{ file?.environment }}
          </v-col>
        </v-row>

        <ooliba-basic-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :selected.sync="selectedFileVersion"
          item-key="versionNumber"
          no-pagination
          @row-clicked="onRowClick"
        >
          <template #[`item.origin`]="{ item }">
            <router-link
              v-if="item.runId"
              :to="{
                name: 'Run',
                params: { runId: item.runId },
              }"
              class="ooliba-font-color-blue"
              >{{ item.origin }}</router-link
            >
          </template>
          <template #[`item.dateCreated`]="{ item }">
            {{ formatDate(item.dateCreated) }}</template
          >
        </ooliba-basic-table>

        <h6 class="py-4">
          {{ details }}
        </h6>

        <v-row v-if="!!selectedFileVersion" dense>
          <v-col cols="12">
            <v-textarea
              :label="$t('global.concepts.comment')"
              v-model="selectedFileVersion.comment"
              outlined
              disabled
              persistent-placeholder
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="showUpload" width="1000">
      <upload-file
        :fileName="file?.name"
        :fileEnvName="file?.environment"
        is-edit
        :uploading="uploading"
        @upload="onUpload($event)"
        @cancel="onCancel"
      ></upload-file>
    </v-dialog>
    <v-dialog v-model="showWarnings" width="500">
      <file-upload-warnings
        :uploading="uploading"
        @cancel="onConfirmCancel"
        @confirmUpload="onConfirmUpload"
        :warnings="warnings"
      ></file-upload-warnings>
    </v-dialog>
  </module-template>
</template>

<script>
import ModuleTemplate from "@/components/layout/ModuleTemplate";
import { get, downloadFile, postForm } from "@/model/api";
import { formatDate } from "@/model/util";

import UploadFile from "@/components/UploadFile";
import FileUploadWarnings from "@/components/run/FileUploadWarnings";

import OolibaBasicTable from "@/components/OolibaBasicTable";

export default {
  name: "FileVersions",

  components: {
    OolibaBasicTable,
    ModuleTemplate,
    UploadFile,
    FileUploadWarnings,
  },

  data() {
    return {
      headers: [
        { value: "versionNumber", text: this.$t("global.concepts.version") },
        { value: "dateCreated", text: this.$t("global.concepts.created-on") },
        { value: "createdBy", text: this.$t("global.concepts.created-by") },
        { value: "origin", text: this.$t("global.concepts.origin") },
        { value: "fileSize", text: this.$t("global.concepts.size") },
      ],

      items: [],
      loading: false,
      file: null,
      fileVersions: [],
      selectedFileVersion: null,
      vaultWriteAccess: false,

      showWarnings: false,
      warnings: [],
      uploadParameters: {},
      showUpload: false,
      uploading: false,
    };
  },

  computed: {
    details() {
      return (
        this.$t("global.concepts.details") +
        " version " +
        this.selectedFileVersion?.versionNumber
      );
    },
  },

  async mounted() {
    this.loading = true;

    const fileId = this.$route.params.fileId;
    this.file = await this.getFile(fileId);
    this.vaultWriteAccess = await this.checkVaultAccess(this.file?.vaultId);
    this.loadFileVersions();

    this.loading = false;
  },

  methods: {
    formatDate,
    onRowClick(clickedItem) {
      this.selectedFileVersion = clickedItem;
    },

    async loadFileVersions() {
      this.loading = true;

      this.fileVersions = await this.getFileVersions(this.$route.params.fileId);

      if (this.fileVersions) {
        this.items = this.setItems(this.fileVersions);
      }
      this.selectedFileVersion = this.items ? this.items[0] : undefined;

      this.loading = false;
    },

    listFiles() {
      this.$router.push({ name: "Files" });
    },

    setItems(files) {
      let items = [];

      files.forEach((value) => {
        let item = {};

        item.id = value.id;
        item.versionNumber = value.versionNumber;
        item.dateCreated = value.dateCreated;
        item.createdBy = value.createdBy;
        item.fileSize = `${Math.ceil(value.fileSize / 1000)} KB`;
        item.commentCreatedBy = value.commentCreatedBy;
        item.commentDateCreated = value.commentDateCreated;
        item.comment = value.comment;
        item.origin =
          value.runTitle && value.runId
            ? value.runTitle + " (#" + value.runId + ")"
            : "";
        item.runId = value.runId;

        items.push(item);
      });
      return items;
    },

    onShowUpload() {
      this.showUpload = true;
    },

    onDownload() {
      const href =
        "file/file-version/" + this.selectedFileVersion.id + "/download";

      downloadFile(href).catch((err) => {
        this.$store.commit("showError", err);
      });
    },

    async checkVaultAccess(vaultId) {
      return await get("/vault/" + vaultId + "/write-access").catch((err) =>
        this.$store.commit("showError", err)
      );
    },

    async getFile(fileId) {
      const path = `/file/${fileId}`;

      return await get(path).catch((err) =>
        this.$store.commit("showError", err)
      );
    },

    async getFileVersions(fileId) {
      const path = `/file/${fileId}/versions`;

      return await get(path).catch((err) =>
        this.$store.commit("showError", err)
      );
    },

    onCancel() {
      this.showUpload = false;
    },

    async onUpload(uploadParameters, force) {
      this.uploading = true;
      const formData = new FormData();

      this.uploadParameters.fileName = uploadParameters.fileName;
      this.uploadParameters.environment = uploadParameters.environment;
      this.uploadParameters.file = uploadParameters.file;
      this.uploadParameters.comment = uploadParameters.comment;

      formData.append("fileName", uploadParameters.fileName);
      formData.append("environment", uploadParameters.environment);
      formData.append("file", uploadParameters.file);
      formData.append("force", force);
      formData.append("comment", uploadParameters.comment);

      await postForm("/file/update", formData)
        .then((res) => {
          this.uploading = false;
          this.uploaded(res);
        })
        .catch((error) => {
          this.uploading = false;
          this.$store.commit("showError", error);
        });
    },

    async uploaded(res) {
      if (res.messages?.length > 0) {
        this.showUpload = false;
        this.showWarnings = true;
        this.warnings = res.messages;
      } else {
        this.loadFileVersions().then(() => {
          this.showUpload = false;
          this.showWarnings = false;
        });
      }
    },

    onConfirmCancel() {
      this.showWarnings = false;
    },

    onConfirmUpload() {
      if (this.uploadParameters) {
        this.onUpload(this.uploadParameters, true);
      }
    },
  },
};
</script>
