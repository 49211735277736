<template>
  <module-template id="scrolltainer" :title="formTitle">
    <v-card>
      <v-card-text>
        <v-form @submit.prevent="submit" class="mx-auto" v-model="valid">
          <v-row
            ><v-col cols="6">
              <v-text-field
                v-model="name"
                :label="$t('global.concepts.name') + '*'"
                :rules="[notEmptyRule, noTagsRule]"
                :disabled="isDefault"
                outlined
                persistent-placeholder
              >
              </v-text-field> </v-col
          ></v-row>
          <v-row
            ><v-col cols="6">
              <v-autocomplete
                :items="parentNames"
                :label="$t('global.concepts.parent') + '*'"
                :rules="[notEmptyRule]"
                v-model="parent"
                :disabled="isDefault"
                outlined
                persistent-placeholder
              ></v-autocomplete> </v-col
          ></v-row>
          <v-row
            ><v-col cols="6">
              <v-text-field
                v-model="label"
                :label="$t('global.environment.label') + '*'"
                :disabled="isDefault"
                :maxlength="4"
                outlined
                :rules="[notEmptyRule, noTagsRule]"
                persistent-placeholder
              >
              </v-text-field> </v-col
          ></v-row>

          <v-radio-group
            v-model="color"
            row
            :label="$t('global.concepts.background-color')"
          >
            <v-radio v-for="clr in colors" :key="clr" :value="clr">
              <template v-slot:label
                ><file-env-chip
                  :color="clr"
                  :label="colorChipValue"
                ></file-env-chip
              ></template>
            </v-radio>
          </v-radio-group>

          <v-row
            ><v-col cols="6">
              <v-autocomplete
                :items="vaultNames"
                :label="$t('global.administration.vault') + '*'"
                :rules="[notEmptyRule]"
                v-model="vault"
                :disabled="isDefault"
                outlined
                persistent-placeholder
              ></v-autocomplete></v-col
          ></v-row>

          <form-buttons
            :confirmText="
              isEdit ? $t('global.action.edit') : $t('global.action.create')
            "
            :canSubmit="valid"
            @cancel="onCancel"
          ></form-buttons>
        </v-form>
      </v-card-text>
    </v-card>
  </module-template>
</template>

<script>
import { post, get, put } from "@/model/api";
import ModuleTemplate from "@/components/layout/ModuleTemplate";
import FileEnvChip from "@/components/FileEnvChip";
import FormButtons from "@/components/FormButtons";
import { notEmpty, noTags } from "@/model/rules";

export default {
  name: "CreateEditFileEnv",

  components: {
    ModuleTemplate,
    FileEnvChip,
    FormButtons,
  },

  props: {
    isEdit: Boolean,
    fileEnvId: Number,
    cancelRoute: String,
  },

  computed: {
    formTitle() {
      if (this.isEdit) {
        return this.$t("global.header.edit", [
          this.$t("global.concepts.file-environment").toLowerCase(),
        ]);
      }
      return this.$t("global.header.create", [
        this.$t("global.concepts.file-environment").toLowerCase(),
      ]);
    },

    colorChipValue() {
      return this.label ? this.label : "dft";
    },

    buttonText() {
      return this.isEdit ? "Edit " : "Create ";
    },
  },

  data() {
    return {
      name: "",
      parent: "",
      label: "",
      vault: "",
      color: "",
      isDefault: false,

      vaultNames: [],
      parentNames: [],
      colors: [],
      notEmptyRule: notEmpty(this),
      noTagsRule: noTags(this),
      valid: false,

      isBusy: true,
    };
  },
  methods: {
    onCancel() {
      this.$router.push({ name: this.cancelRoute });
    },

    async getVaults() {
      return await get("/vault")
        .then((res) => {
          return res;
        })
        .catch((e) => this.$store.commit("showError", e));
    },

    async getEnvs() {
      return await get("/file-env")
        .then((res) => {
          return res;
        })
        .catch((e) => this.$store.commit("showError", e));
    },

    async getColors() {
      return await get("/file-env/colors").catch((e) =>
        this.$store.commit("showError", e)
      );
    },

    async submit() {
      const data = {
        id: this.fileEnvId,
        name: this.name,
        parent: this.parent,
        label: this.label,
        vault: this.vault,
        color: this.color,
      };
      if (this.isEdit) await this.editFileEnv(data);
      else await this.createFileEnv(data);
    },

    async createFileEnv(data) {
      return await post("/file-env/save", data)
        .then(() => {
          this.$router.push({ name: "File envs" });
        })
        .catch((e) => {
          this.$store.commit("showError", this.createErrorMsg(e));
        });
    },

    async editFileEnv(data) {
      return await put("/file-env", data)
        .then(() => {
          this.$router.push({ name: "File envs" });
        })
        .catch((e) => {
          this.$store.commit("showError", this.createErrorMsg(e));
        });
    },

    createErrorMsg(e) {
      if (e.message !== "unique.constraint" || !e.arguments) {
        return e;
      }
      if (e.arguments[0] === "name") {
        return this.$t("global.msg.already-exists", [
          this.$t("global.concepts.file-environment").toLowerCase(),
          this.$t("global.concepts.name").toLowerCase(),
          e.arguments[1],
        ]);
      }
      if (e.arguments[0] === "label") {
        return this.$t("global.msg.already-exists", [
          this.$t("global.concepts.file-environment").toLowerCase(),
          this.$t("global.environment.label").toLowerCase(),
          e.arguments[1],
        ]);
      }
      return this.$t("global." + e.message, e.arguments);
    },

    async getFileEnv(id) {
      return await get("/file-env/" + id)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.$store.commit("showError", error);
        });
    },

    async isDefaultFilEnv(id) {
      return await get("/file-env/" + id + "/is-default")
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.$store.commit("showError", error);
        });
    },
  },
  async created() {
    const vaults = await this.getVaults();
    this.vaultNames = [];
    if (vaults) {
      this.vaultNames = ["default"];
      vaults.forEach((element) => {
        if (element.name !== "default") {
          this.vaultNames.push(element.name);
        }
      });
    }
    const envs = await this.getEnvs();
    if (envs) {
      this.parentNames = ["default"];
      envs.forEach((v) => {
        if (v.name !== "default") {
          this.parentNames.push(v.name);
        }
      });
    }
    this.colors = await this.getColors();

    if (this.isEdit) {
      const fileEnv = await this.getFileEnv(this.fileEnvId);

      this.name = fileEnv.name;
      this.parent = fileEnv.parent === "" ? "-" : fileEnv.parent;
      this.label = fileEnv.label;
      this.color = fileEnv.color;
      this.vault = fileEnv.vault;

      this.isDefault = await this.isDefaultFilEnv(this.fileEnvId);
    } else {
      if (this.vaultNames.length > 0) {
        this.vault = this.vaultNames[0];
      }
      if (this.parentNames.length > 0) {
        this.parent = this.parentNames[0];
      }
      if (this.colors.length > 0) {
        this.color = this.colors[0];
      }
    }
    this.busy = false;
  },
};
</script>
