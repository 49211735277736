<template>
  <v-card>
    <v-overlay absolute :value="uploading">
      <v-chip color="blue" dark>{{ $t("global.msg.uploading") }}</v-chip>
    </v-overlay>
    <v-card-title>{{ title }}</v-card-title>
    <v-form @submit.prevent="upload" class="mx-auto" v-model="valid" ref="form">
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              :disabled="!!fileName"
              v-model="fileData.fileName"
              :label="$t('global.concepts.name') + '*'"
              :rules="[notEmptyRule, noTagsRule]"
              outlined
              persistent-placeholder
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-autocomplete
              :disabled="!!fileEnvName"
              :items="fileEnvNames"
              :label="$t('global.environment.environment') + '*'"
              :rules="[notEmptyRule]"
              v-model="fileData.environment"
              outlined
              persistent-placeholder
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-file-input
              show-size
              :label="$t('global.concepts.choose-file')"
              accept=".xls,.xlsx,.xlsm,.xlsb,.csv"
              :rules="[notEmptyRule]"
              truncate-length="100"
              v-model="fileData.file"
              outlined
              @change="onFileChange"
            ></v-file-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              :label="$t('global.concepts.comment')"
              v-model="fileData.comment"
              outlined
              :rules="[noTagsRule]"
              persistent-placeholder
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="onCancel">{{ $t("global.action.cancel") }}</v-btn>
        <v-btn color="primary" :disabled="!valid" type="submit">{{
          title
        }}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { get } from "@/model/api";
import { notEmpty, noTags } from "@/model/rules";

export default {
  name: "UploadFile",

  props: {
    fileName: {},
    fileEnvName: String,
    isEdit: Boolean,
    uploading: Boolean,
  },

  data() {
    return {
      fileData: {
        fileName: this.fileName,
        environment: this.fileEnvName,
        comment: "",
        file: undefined,
      },
      fileEnvNames: [],
      valid: false,
      notEmptyRule: notEmpty(this),
      noTagsRule: noTags(this),
    };
  },

  computed: {
    title() {
      return this.$t("global.action.upload");
    },
  },

  watch: {
    fileName: function () {
      this.fileData.fileName = this.fileName;
    },
    fileEnvName: function () {
      this.fileData.environment = this.fileEnvName;
    },
  },

  methods: {
    upload() {
      this.$emit("upload", this.fileData);
      if (this.fileEnvName) {
        this.fileData.file = undefined;
      } else {
        this.$refs.form.reset();
      }
    },

    onFileChange() {
      this.fileData.comment = this.fileData?.file?.name;
    },

    onCancel(event) {
      this.$emit("cancel", event);
      if (this.fileEnvName) {
        this.fileData.file = undefined;
      } else {
        this.$refs.form.reset();
      }
    },

    async getFileEnvs() {
      return await get("/file-env").catch((error) => {
        this.$store.commit("showError", error);
      });
    },
  },

  async created() {
    const fileEnvs = await this.getFileEnvs();

    if (fileEnvs) {
      this.fileEnvNames = [];
      let containsPropFileEnv = false;

      fileEnvs.forEach((element) => {
        this.fileEnvNames.push(element.name);
        containsPropFileEnv = this.fileEnvName === element.name;
      });
      if (!containsPropFileEnv) {
        this.fileEnvNames.unshift(this.fileEnvName);
      }
    }
  },
};
</script>
