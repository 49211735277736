<template>
  <module-template
    id="scrolltainer"
    title
    :canDelete="$store.state.bpInstDeletePermission && !locked"
    :canEdit="$store.state.bpInstRenamePermission"
    :canLock="$store.state.bpInstLockPermission && !locked"
    :canUnlock="$store.state.bpInstLockPermission && locked"
    canList
    @deleteItem="deleteBp"
    @editItem="editBp"
    @list="listBps"
    @lock="toggleLock"
    @unlock="toggleLock"
  >
    <template #title>
      <v-row dense class="px-1" align="center">
        {{ $t("global.business-process.bp-instance") }}
        <v-icon color="white" class="px-3" size="9">pic-arrow-right</v-icon>
        <div class="mr-2">
          {{ "#" + bpInstance?.id }}
        </div>
        <v-text-field
          dark
          v-model="editingName"
          type="text"
          v-if="showEdit"
          :rules="[noTagsRule]"
        >
          <template #append-outer
            ><v-btn
              @click="onEditConfirm"
              class="mr-1"
              dark
              fab
              outlined
              small
              :disabled="!canEditName"
              ><v-icon>pic-check</v-icon></v-btn
            >
            <v-btn @click="showEdit = false" dark fab outlined small
              ><v-icon>pic-close</v-icon></v-btn
            >
          </template></v-text-field
        >
        <div v-else>{{ bpInstance?.title }}</div>
      </v-row>
    </template>

    <v-row dense class="mb-3">
      <v-col>
        <basic-card-horizontal
          :headers="leftCardHeaders"
          :item="leftCardItems"
          v-if="bpInstance"
        >
          <template #[`item.env`]="{ item }">
            <router-link :to="envRoute">
              <file-env-chip :color="item.envColor" :label="item.env">
              </file-env-chip>
            </router-link>
          </template>

          <template #[`item.definition`]="{ item }">
            <router-link :to="definitionRoute">{{
              item.definition
            }}</router-link>
          </template>

          <template #[`item.createdBy`]="{ item }">
            <router-link :to="createdByRoute">{{ item.createdBy }}</router-link>
          </template>

          <template #[`item.localBp`]="{ item }">
            <v-checkbox
              v-model="item.localBp"
              dense
              disabled
              hide-details
              class="pt-0 mt-0"
            ></v-checkbox>
          </template>

          <template #[`item.status`]="{ item }">
            {{ item.status }}
            <v-tooltip v-if="locked" top>
              <template #activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" small>pic-lock-fill</v-icon>
              </template>
              <span>{{ lockMessage }}</span>
            </v-tooltip>
          </template>
        </basic-card-horizontal>
      </v-col>

      <v-col>
        <basic-card-horizontal
          :headers="rightCardHeaders"
          :item="rightCardItems"
          v-if="bpInstance"
        >
        </basic-card-horizontal>
      </v-col>
    </v-row>

    <v-card>
      <BPInstanceImage
        v-if="flowUserImage.id"
        :flowInstanceId="bpInstance.flowInstanceId"
        :flowId="flow?.id"
        :flowUserImageWidth="flowUserImage?.width"
        :flowUserImageHeight="flowUserImage?.height"
        :flowUserImageZoomable="flowUserImage?.zoomable"
        :pause-updates="deleting"
        @click="onTaskClick"
      />
    </v-card>
    <task-details
      v-if="clickedTask && clickedTaskConfigVersion"
      :flowTaskId="clickedTask.id"
      :name="clickedTaskConfigVersion.name"
      :fileEnv="bpInstance?.fileEnvName"
      :locked="bpInstance?.lockUser"
    />
  </module-template>
</template>

<script>
import { get, put, remove } from "@/model/api";
import TaskDetails from "@/components/businessProcess/TaskDetails";
import BPInstanceImage from "../../components/businessProcess/BPInstanceImage";
import ModuleTemplate from "@/components/layout/ModuleTemplate";
import BasicCardHorizontal from "@/components/BasicCardHorizontal";
import FileEnvChip from "@/components/FileEnvChip";
import { noTags } from "@/model/rules";
import { formatDate } from "@/model/util";

export default {
  name: "BPInstance",

  components: {
    ModuleTemplate,
    TaskDetails,
    BPInstanceImage,
    BasicCardHorizontal,
    FileEnvChip,
  },
  data() {
    return {
      leftCardHeaders: [
        { text: this.$t("global.concepts.status"), value: "status" },
        {
          text: this.$t("global.business-process.definition"),
          value: "definition",
          class: "ooliba-font-color-blue",
        },
        { text: this.$t("global.concepts.created-on"), value: "dateCreated" },
        {
          text: this.$t("global.administration.owner"),
          value: "createdBy",
          class: "ooliba-font-color-blue",
        },
        { text: this.$t("global.environment.environment"), value: "env" },
        { text: this.$t("global.business-process.local-bp"), value: "localBp" },
      ],

      bpInstance: {},
      flow: {},
      flowUserImage: {},
      clickedTask: undefined,
      clickedTaskConfigVersion: undefined,

      showEdit: false,
      editingName: undefined,

      deleting: false,

      noTagsRule: noTags(this),
    };
  },

  computed: {
    leftCardItems() {
      const envLabel = this.bpInstance?.fileEnvIsLocal
        ? this.bpInstance?.parentFileEnvLabel
        : this.bpInstance?.fileEnvLabel;
      const envColor = this.bpInstance?.fileEnvIsLocal
        ? this.bpInstance?.parentFileEnvColor
        : this.bpInstance?.fileEnvColor;
      return {
        status: this.bpInstance?.status,
        definition:
          this.bpInstance?.definitionName +
          " (v." +
          this.bpInstance?.definitionVersion +
          ")",
        dateCreated: formatDate(this.bpInstance?.dateCreated),
        createdBy: this.bpInstance?.createdBy,
        env: envLabel,
        envColor: envColor,
        localBp: this.bpInstance?.local,
      };
    },

    rightCardHeaders() {
      if (this.bpInstance?.props) {
        return Object.keys(this.bpInstance.props).map((prop) => {
          return { text: prop, value: prop };
        });
      }
      return [];
    },

    rightCardItems() {
      const item = {};
      this.rightCardHeaders.forEach((header) => {
        item[header.value] = this.bpInstance.props[header.value];
      });
      return item;
    },

    locked() {
      return !!this.bpInstance?.lockUser;
    },

    lockMessage() {
      const parsedDate = formatDate(this.bpInstance?.lockDate);

      return this.$t("global.business-process.lock-message", [
        parsedDate,
        this.bpInstance?.lockUser,
      ]);
    },

    envRoute() {
      if (!this.bpInstance?.parentFileEnvId && !this.bpInstance?.fileEnvId) {
        return {};
      }
      return {
        name: "File env",
        params: {
          fileEnvId: this.bpInstance.fileEnvIsLocal
            ? this.bpInstance.parentFileEnvId
            : this.bpInstance.fileEnvId,
        },
      };
    },

    definitionRoute() {
      if (!this.bpInstance?.definitionId) {
        return {};
      }
      return {
        name: "BP definition",
        params: {
          bpId: this.bpInstance.definitionId,
        },
      };
    },

    createdByRoute() {
      if (!this.bpInstance?.createdBy) {
        return {};
      }
      return {
        name: "User",
        params: {
          userId: this.bpInstance.createdBy,
        },
      };
    },

    canEditName() {
      return typeof this.noTagsRule(this.editingName) !== "string";
    },
  },

  methods: {
    formatDate,
    listBps() {
      this.$router.push({ name: "BP instances" });
    },

    async getBPInstance(id) {
      return await get("/businessProcessInstance/" + id).catch((error) =>
        this.onError(error)
      );
    },

    async onTaskClick(vertexId) {
      this.clickedTask = await this.getTask(
        this.bpInstance.flowInstanceId,
        vertexId
      );
      this.clickedTaskConfigVersion = await this.getTaskConfigVersion(
        this.clickedTask?.name
      );
    },

    onError(error) {
      this.$store.commit("showError", error);
    },

    async getTask(flowInstanceId, vertexId) {
      return await get(
        "/flow-instance/flow-task/" + flowInstanceId + "/" + vertexId
      ).catch((error) => this.onError(error));
    },

    async getTaskConfigVersion(taskConfigName) {
      return await get(
        "/flow-task-config/current-version/" + taskConfigName
      ).catch((error) => this.onError(error));
    },

    async deleteBp() {
      this.deleting = true;
      await remove("/businessProcessInstance?id=" + this.bpInstance.id)
        .then(() => {
          this.$router.push({ name: "BP instances" });
          this.deleting = false;
          this.$store.commit("removeFromRecentBps", this.bpInstance.id);
        })
        .catch((error) => {
          this.onError(error);
          this.deleting = false;
        });
    },

    onEditConfirm() {
      put(
        "/businessProcessInstance/rename?instanceId=" +
          encodeURIComponent(this.bpInstance.id) +
          "&name=" +
          encodeURIComponent(this.editingName)
      )
        .then((r) => {
          this.editingName = r;
          this.bpInstance.title = r;
          this.showEdit = false;
        })
        .catch((e) => {
          this.$store.commit("showError", e);
          this.showEdit = false;
        });
    },

    toggleLock() {
      put(
        "/businessProcessInstance/toggle-lock/" +
          this.bpInstance?.id +
          "?lock=" +
          !this.bpInstance?.lockUser
      )
        .then((r) => {
          this.bpInstance = r;
        })
        .catch((error) => {
          this.$store.commit("showError", error);
        });
    },

    editBp() {
      this.showEdit = true;
    },

    async getFlow(flowInstancId) {
      return await get("/flow-instance/flow/" + flowInstancId).catch(
        (error) => {
          this.$store.commit("showError", error);
        }
      );
    },
  },

  async created() {
    const bpId = this.$route.params.bpId;
    this.bpInstance = await this.getBPInstance(bpId);

    if (this.bpInstance) {
      this.$store.commit("addToRecentBps", {
        id: bpId,
        name: this.bpInstance?.title,
      });
      this.flow = await this.getFlow(this.bpInstance?.flowInstanceId);
      this.flowUserImage = this.flow?.userImage;
      this.editingName = this.bpInstance?.title;
    }
  },
};
</script>
