<template>
  <module-template
    :canImport="$store.state.importOlpPermission"
    id="scrolltainer"
    :title="$t('global.menu.modules')"
    @import="showImport = true"
  >
    <v-dialog v-model="showImport" width="500">
      <v-overlay absolute :value="uploading">
        <v-chip color="blue" dark>{{ $t("global.msg.importing") }}</v-chip>
      </v-overlay>
      <v-card>
        <v-card-title>{{ $t("global.module.import") }}</v-card-title>
        <v-form @submit.prevent="onImport">
          <v-card-text>
            <v-file-input
              show-size
              :label="$t('global.modules.choose-module')"
              accept=".olp"
              v-model="moduleFile"
              outlined
              truncate-length="50"
            ></v-file-input>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="onCancel">{{ $t("global.action.cancel") }}</v-btn>
            <v-btn type="submit" :disabled="!moduleFile" color="primary">{{
              $t("global.modules.import")
            }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <ooliba-basic-table
      :headers="headers"
      :items="items"
      :loading="busy"
      no-pagination
      sort-by="name"
      @row-clicked="onRowClick"
    >
      <template #[`item.uploadedOn`]="{ item }">
        {{ formatDate(item.uploadedOn) }}
      </template>
    </ooliba-basic-table>
  </module-template>
</template>

<script>
import { get, postForm } from "@/model/api";
import OolibaBasicTable from "@/components/OolibaBasicTable";
import ModuleTemplate from "@/components/layout/ModuleTemplate";
import { formatDate, versionCompare } from "@/model/util";

export default {
  name: "ModuleList",
  components: {
    OolibaBasicTable,
    ModuleTemplate,
  },
  data() {
    return {
      headers: [
        { text: this.$t("global.concepts.name"), value: "name" },
        {
          text: this.$t("global.modules.latest-version"),
          value: "latestVersion",
          sort: (a, b) => this.versionCompare(a, b),
        },
        { text: this.$t("global.concepts.uploaded-on"), value: "uploadedOn" },
      ],
      items: [],
      busy: false,
      uploading: false,

      showImport: false,
      moduleFile: undefined,
    };
  },

  methods: {
    formatDate,
    versionCompare,

    onCancel() {
      this.moduleFile = undefined;
      this.showImport = false;
    },

    async onImport() {
      if (this.moduleFile) {
        this.uploading = true;
        const formData = new FormData();
        formData.append("olpFile", this.moduleFile);

        await postForm("import/importProject", formData)
          .then(() => {
            this.showImport = false;
            this.moduleFile = undefined;
            this.uploading = false;
            this.loadTableData();
          })
          .catch((err) => {
            this.$store.commit(
              "showError",
              this.$t("global." + err.message, err.arguments)
            );
            this.showImport = false;
            this.moduleFile = undefined;
            this.uploading = false;
          });
      }
    },

    async getModules() {
      return await get("release/latest-versions")
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.$store.commit("showError", error);
        });
    },

    onRowClick(record) {
      const name = record.name;

      this.$router.push({
        name: "ModuleVersions",
        params: { moduleName: name },
      });
    },

    async loadTableData() {
      this.busy = true;
      this.items = [];
      this.modules = await this.getModules();

      if (this.modules) {
        this.modules.forEach(async (value) => {
          const item = {};

          item[this.headers[0].value] = value.moduleName;
          item[this.headers[1].value] = value.versionString;
          item[this.headers[2].value] = value.dateCreated;

          this.items.push(item);
        });
      }
      this.busy = false;
    },
  },
  async created() {
    this.loadTableData();
  },
};
</script>
