<template>
  <module-template
    id="scrolltainer"
    title
    :canEdit="$store.state.bpDefModifyPermission"
    canList
    canExport
    :canDelete="$store.state.bpDefDeletePermission"
    @deleteItem="deleteBpDefinition"
    @editItem="onEdit"
    @list="listBps"
    @export="onExport"
  >
    <template #title>
      <v-row dense class="px-1" align="center">
        {{ $t("global.business-process.bp-definition") }}
        <v-icon color="white" class="px-3" size="9">pic-arrow-right</v-icon>
        <div>{{ name }}</div>
      </v-row>
    </template>
    <v-card>
      <v-card-text>
        <ooliba-basic-table
          :headers="headers"
          :items="items"
          :loading="busy"
          no-pagination
          :selected.sync="selectedVersion"
          @row-clicked="onRowClick"
        >
          <template #[`item.createdOn`]="{ item }">
            {{ formatDate(item.createdOn) }}
          </template>
        </ooliba-basic-table>

        <h6 class="py-4">
          {{ details }}
        </h6>

        <v-form
          @submit.prevent="editBpDefinition"
          class="mx-auto"
          v-model="valid"
        >
          <bp-definition-form-fields
            v-if="formData"
            :disabled="!showEdit"
            :isEdit="showEdit"
            :init-values="formData"
            @input="onInput($event)"
          ></bp-definition-form-fields>
          <form-buttons
            v-if="showEdit"
            :confirmText="$t('global.tooltip.effisoftToolbarEdit')"
            :can-submit="valid"
            @cancel="cancelEdit"
          ></form-buttons>
        </v-form>
      </v-card-text>
    </v-card>
    <v-card class="mt-3">
      <v-card-text>
        <BPInstanceImage
          v-if="selectedVersion"
          :flowId="selectedVersion?.flowId"
          :flowUserImageWidth="selectedVersion?.flowUserImageWidth"
          :flowUserImageHeight="selectedVersion?.flowUserImageHeight"
          :flowUserImageZoomable="selectedVersion?.flowUserImageZoomable"
          @click="onTaskClick"
        />
        <v-btn
          v-if="selectedVersion && $store.state.bpDefModifyPermission"
          color="primary"
          class="mb-2"
          @click="onCustomizeImage"
          >{{ $t("global.business-process.customize-image") }}</v-btn
        >
      </v-card-text>
    </v-card>
  </module-template>
</template>

<script>
import ModuleTemplate from "@/components/layout/ModuleTemplate";
import { get, downloadFile, remove, put } from "@/model/api";
import OolibaBasicTable from "@/components/OolibaBasicTable";
import BpDefinitionFormFields from "@/components/businessProcess/bpDefinition/BpDefinitionFormFields";
import FormButtons from "@/components/FormButtons";
import BPInstanceImage from "@/components/businessProcess/BPInstanceImage.vue";
import { createInvalidGraphError } from "@/model/util";
import { formatDate } from "@/model/util";

export default {
  name: "BPDefinition",
  components: {
    ModuleTemplate,
    OolibaBasicTable,
    BpDefinitionFormFields,
    FormButtons,
    BPInstanceImage,
  },
  data() {
    return {
      selectedVersion: undefined,
      bpDefinition: {},
      name: "",
      formData: undefined,
      bpVersions: [],
      headers: [
        { text: this.$t("global.concepts.version"), value: "version" },
        { text: this.$t("global.concepts.created-on"), value: "createdOn" },
        { text: this.$t("global.concepts.created-by"), value: "createdBy" },
      ],
      items: [],
      showEdit: false,
      valid: false,
      busy: true,
    };
  },

  computed: {
    details() {
      return (
        this.$t("global.concepts.details") +
        " version " +
        this.selectedVersion?.version
      );
    },
  },

  methods: {
    formatDate,
    listBps() {
      this.$router.push({ name: "BP definitions" });
    },

    onInput(data) {
      this.formData = data;
    },

    onEdit() {
      this.showEdit = true;
    },

    async onExport() {
      await downloadFile(
        "/business-process/export/" + this.selectedVersion.id
      ).catch((ex) => {
        this.$store.commit("showError", ex);
      });
    },

    async deleteBpDefinition() {
      await remove("/business-process/" + this.$route.params.bpId)
        .then(() => {
          this.$router.push({ name: "BP definitions" });
        })
        .catch((error) => {
          this.$store.commit("showError", error);
        });
    },
    async editBpDefinition() {
      await put("/business-process/" + this.$route.params.bpId, this.formData)
        .then(() => {
          this.showEdit = false;
          this.loadData();
        })
        .catch((e) => {
          this.$store.commit("showError", createInvalidGraphError(e));
        });
    },

    async getBpDefinition(id) {
      return await get("/business-process/" + id).catch((error) => {
        this.$store.commit("showError", error);
      });
    },
    async getBpVersions(id) {
      return await get("/business-process/" + id + "/versions").catch(
        (error) => {
          this.$store.commit("showError", error);
        }
      );
    },
    cancelEdit() {
      this.showEdit = false;
      this.loadData();
    },

    onRowClick(clickedRow) {
      this.selectedVersion = clickedRow;
      this.formData = this.createFormData(
        this.bpDefinition,
        this.selectedVersion
      );
    },

    onCustomizeImage() {
      const bpVersionId = this.selectedVersion?.id;

      if (bpVersionId) {
        this.$router.push({
          name: "Edit BP definition image",
          params: { bpVersionId: bpVersionId },
        });
      }
    },

    createFormData(bpDef, bpDefVersion) {
      const formData = {};

      formData.name = bpDef?.name;
      formData.description = bpDefVersion?.description;
      formData.form = bpDefVersion?.propertyEditorDefinition;
      formData.jsonGraph = bpDefVersion?.flowJsonGraph;

      return formData;
    },

    async getTask(processVersionId, vertexId) {
      return await get(
        "/business-process-version/" +
          processVersionId +
          "/flow-task-config/" +
          vertexId
      ).catch((error) => this.onError(error));
    },
    async onTaskClick(vertexId) {
      if (this.selectedVersion) {
        const task = await this.getTask(this.selectedVersion.id, vertexId);

        this.$router.push({
          name: "Process task",
          params: { taskId: task.id },
        });
      }
    },
    async loadData() {
      this.busy = true;
      const bpId = this.$route.params.bpId;

      this.bpDefinition = await this.getBpDefinition(bpId);
      this.name = this.bpDefinition?.name;
      this.bpVersions = await this.getBpVersions(bpId);

      if (this.bpVersions) {
        this.items = [];
        this.bpVersions.forEach((value) => {
          const item = {};

          item["id"] = value.id;
          item[this.headers[0].value] = value.versionNumber;
          item[this.headers[1].value] = value.dateCreated;
          item[this.headers[2].value] = value.createdBy;
          item.description = value.description;
          item.propertyEditorDefinition = value.propertyEditorDefinition;
          item.name = value.bpName;
          item.flowId = value.flowId;
          item.flowJsonGraph = value.flowJsonGraph;
          item.flowUserImageWidth = value.flowUserImageWidth;
          item.flowUserImageHeight = value.flowUserImageHeight;
          item.flowUserImageZoomable = value.flowUserImageZoomable;

          this.items.push(item);
        });
        this.selectedVersion = this.items ? this.items[0] : undefined;
        if (this.bpDefinition && this.selectedVersion) {
          this.formData = this.createFormData(
            this.bpDefinition,
            this.selectedVersion
          );
        }
      }
      this.busy = false;
    },
  },
  async created() {
    await this.loadData();
  },
};
</script>
