<template>
  <v-card>
    <v-card-title>{{ title }}</v-card-title>
    <v-form @submit.prevent="addEditProperty" v-model="valid">
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              v-model="property.name"
              :label="$t('global.concepts.name') + '*'"
              :rules="keyRules"
              outlined
              persistent-placeholder
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              v-model="property.value"
              :label="$t('global.concepts.value')"
              :rules="valueRules"
              auto-grow
              outlined
              persistent-placeholder
            >
            </v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="onCancel">{{ $t("global.action.cancel") }}</v-btn>
        <v-btn :disabled="!valid" type="submit" color="primary">
          {{ buttonText }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { post, put } from "@/model/api";
import { notEmpty, noTags } from "@/model/rules";

export default {
  name: "AddEditProperty",
  props: {
    propertyId: Number,
    isEdit: Boolean,
    initProperty: Object,
  },

  computed: {
    buttonText() {
      return this.isEdit
        ? this.$t("global.tooltip.effisoftToolbarEdit")
        : this.$t("global.tooltip.effisoftToolbarAdd");
    },

    title() {
      return this.buttonText + " " + this.$t("global.property.property");
    },
  },

  data() {
    return {
      property: {},
      keyRules: [notEmpty(this), noTags(this)],
      valueRules: [noTags(this)],
      valid: false,
    };
  },
  methods: {
    async addEditProperty() {
      const data = {
        name: this.property.name,
        value: this.property.value,
      };
      if (this.isEdit) await this.editProp(data);
      else await this.addProp(data);
    },

    async addProp(data) {
      await post("/environment-property/save", data)
        .then(() => {
          this.$emit("done");
        })
        .catch((e) => {
          this.$store.commit("showError", this.createUniqueConstraintMsg(e));
        });
    },

    async editProp(data) {
      await put("/environment-property/" + this.initProperty.id, data)
        .then(() => {
          this.$emit("done");
        })
        .catch((e) => {
          this.$store.commit("showError", this.createUniqueConstraintMsg(e));
        });
    },

    createUniqueConstraintMsg(e) {
      if (e.message === "unique.constraint" && e.arguments) {
        if (e.arguments[0] === "name") {
          return this.$t("global.msg.already-exists", [
            this.$t("global.property.property"),
            this.$t("global.concepts.name"),
            e.arguments[1],
          ]);
        }
      }
      return e;
    },

    onCancel(event) {
      this.$emit("cancel", event);
    },
  },
  created() {
    this.property = this.initProperty;
  },
};
</script>
